.cookieNotice {
  background-color: #243460;
  border: 2px solid #fe7165;
  /* max-width: 1440px; */
}
.cookieNotice .title-wrap {
  font-family: Poppins;
  letter-spacing: -0.015em;
  margin: 0 0 10px;
}
.cookieNotice .msg-wrap span {
  font-family: Poppins;
  letter-spacing: -0.015em;
}
.cookieNotice .btn-wrap .btn {
  width: max-content;
  padding: 5px 10px;
}
.cookieNotice .btn-wrap .btn-accept {
  color: #243060;
  margin-right: 10px;
}
.cookieNotice .btn-wrap .btn-denied {
  border: 1.35px solid #ffffff;
}

@media screen and (max-width: 600px) {
  .cookieNotice .msg-wrap {
    flex-direction: column;
  }
  .cookieNotice .btn-wrap {
    padding-top: 10px;
    align-items: flex-start;
  }
}
